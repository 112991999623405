(function () {
  let isScrolling = false;

  /**
   * Smooth scroll implementation
   * @param {number} targetPosition - The final position of the scroll.
   * @param {number} duration - The duration of the scroll animation in ms.
   * @param {function} callback - The callback function to run after the animation.
   */
  const smoothScroll = (targetPosition, duration, callback) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    /**
     * Animation loop
     * @param {number} currentTime - The current time of the animation.
     */
    const animation = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      // Easing function
      // @see https://gist.github.com/gre/1650294
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

      window.scrollTo(0, startPosition + distance * easeInOutQuad(progress));

      if (progress < 1) {
        requestAnimationFrame(animation);
      } else if (callback) {
        callback();
      }
    };

    requestAnimationFrame(animation);
  };

  /**
   * Initializes tab switching functionality.
   * Attaches event listeners to sidebar links for switching tabs.
   */
  const initializeTabs = () => {
    const sidebarLinks = document.querySelectorAll(".wpcb-lotto-single__sidebar > a");
    if (!sidebarLinks.length) return;

    sidebarLinks.forEach((link) => {
      link.addEventListener("click", function (e) {
        if (isScrolling) return; // Prevent action during scrolling
        isScrolling = true;

        const tabId = this.getAttribute("data-tab");
        const tabIcon = this.getAttribute("data-icon");
        const tabTitle = this.textContent;

        // Remove active class from all links
        sidebarLinks.forEach((link) => link.classList.remove("wpcb-lotto-single__sidebar-link-active"));

        // Hide all tab contents
        document.querySelectorAll(".wpcb-lotto-single__content").forEach((tab) => {
          tab.style.display = "none";
        });

        // Activate current tab
        this.classList.add("wpcb-lotto-single__sidebar-link-active");
        const currentTab = document.getElementById(`wpcb-lotto-tab-${tabId}`);
        currentTab.style.display = "block";

        // Update header with tab title and icon
        document.querySelector(".wpcb-lotto-single__header-title").textContent = tabTitle;
        const headerIcon = document.querySelector(".wpcb-lotto-single__header-icon");
        headerIcon.src = tabIcon;
        headerIcon.alt = tabTitle;

        // Smooth scroll to tab content
        const targetPosition = document.querySelector(".wpcb-lotto-single").offsetTop - 100;
        smoothScroll(targetPosition, 500, () => {
          isScrolling = false; // Reset scrolling flag after animation
        });
      });
    });
  };

  /**
   * @typedef {Object} lottoThaiSingle
   * @property {(function(number):string):string} numberFormat - Format the number to display.
   * @property {(function():boolean|Array<string>):boolean|Array<string>} checkable - Check if the URL has a valid hash.
   * @property {(function(values: string[], lottoData: Object):Array<Object>):Array<Object>} check - Check the numbers against the lotto data.
   * @property {(function(number: string, lottoData: Object):Object):Object} checkTheNumber - Check a single number against the lotto data.
   * @property {(function(values: string[])):void} displayResult - Display the result of the check.
   * @property {(function():void):void} handleChecking - Handle the checking of the numbers.
   * @property {(function():void):void} handleCheckingSubmit - Handle the submission of the form.
   */
  const lottoThaiSingle = {
    /**
     * Format the number to display.
     *
     * @param {number} value - The number to format.
     * @returns {string} The formatted number.
     */
    numberFormat(value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    /**
     * Check if the URL has a valid hash.
     *
     * @returns {boolean|Array<string>} True if the URL has a valid hash, or an array of numbers if the URL has a valid hash.
     */
    checkable() {
      let hash = window.location.hash;
      if (hash.includes("#")) {
        hash = hash.replace("#", "");
        const regex = /([0-9]{6})(,)?/;
        const match = regex.exec(hash);

        if (!match) return false;

        const listHash = hash.split(",");
        return listHash.every((num) => !isNaN(parseInt(num))) ? listHash : false;
      }
      return false;
    },

    /**
     * Check the numbers against the lotto data.
     *
     * @param {string[]} values - The numbers to check.
     * @param {Object} lottoData - The lotto data.
     * @returns {Array<Object>} The checked numbers with their prizes.
     */
    check(values, lottoData) {
      return values.filter((value) => value.length === 6).map((value) => this.checkTheNumber(value, lottoData));
    },

    /**
     * Check a single number against the lotto data.
     *
     * @param {string} value - The number to check.
     * @param {Object} lottoData - The lotto data.
     * @returns {Object} The checked number with its prize.
     */
    checkTheNumber(value, lottoData) {
      const data = {
        isWin: false,
        number: value,
        prize: false,
        reward: false,
      };

      const d2 = value.slice(4, 6);
      const d3f = value.slice(0, 3);
      const d3l = value.slice(3, 6);

      const setPrizeData = (prizeText, reward) => {
        data.isWin = true;
        data.prize = prizeText;
        data.reward = wpcbLotto.i18n.thaiLotto.check[9999].replace("#NUMBER#", this.numberFormat(reward));
      };

      Object.entries(lottoData).forEach(([idx, lotto]) => {
        lotto.numbers.forEach((number) => {
          if (value === number) {
            setPrizeData(wpcbLotto.i18n.thaiLotto.check[idx], lotto.reward);
          } else if (d2 === number) {
            // Won the last 2 digit prize
            setPrizeData(wpcbLotto.i18n.thaiLotto.check[7], lotto.reward);
          } else if (d3l === number && idx == 6) {
            // Won the last 3 digit prize
            setPrizeData(wpcbLotto.i18n.thaiLotto.check[6], lotto.reward);
          } else if (d3f === number && idx == 10) {
            // Won the first 3 digit prize
            setPrizeData(wpcbLotto.i18n.thaiLotto.check[10], lotto.reward);
          }
        });
      });

      return data;
    },

    /**
     * Display the result of the check.
     *
     * @param {string[]} values - The numbers to display.
     */
    displayResult(values) {
      if (typeof wpcbLotto === "undefined" || typeof wpcbLotto.lottoThaiResult === "undefined") {
        return;
      }

      const lottoData = JSON.parse(wpcbLotto.lottoThaiResult);
      const check = this.check(values, lottoData);

      const displayResult = document.getElementById("wpcbLottoThaiResult");
      if (displayResult && wp.template) {
        const template = wp.template("lotto-thai-check");
        displayResult.innerHTML = template(check);

        const resultPosition = displayResult.offsetTop - 100;
        smoothScroll(resultPosition, 500);
      }
    },

    /**
     * Handle the checking of the numbers.
     */
    handleChecking() {
      const checkable = this.checkable();
      if (!checkable) return;

      this.displayResult(checkable);
    },

    /**
     * Handle the submission of the form.
     */
    handleCheckingSubmit() {
      const form = document.getElementById("wpcbLottoThaiCheckForm");
      if (!form) return;

      form.addEventListener("submit", (e) => {
        e.preventDefault();

        const lottoValues = [];
        const codeInputs = form.querySelectorAll('[name="code[]"]');
        let hash = "";

        codeInputs.forEach((input) => {
          const valueCode = input.value.trim();
          if (valueCode.length === 6) {
            hash += hash === "" ? "#" : ",";
            hash += valueCode;
            lottoValues.push(valueCode);
          }
        });

        if (lottoValues.length > 0) {
          const baseHref = location.origin + location.pathname;
          window.location.href = baseHref + hash;

          this.displayResult(lottoValues);
        } else {
          alert(wpcbLotto.i18n.thaiLotto.error.invalidTicket);
        }
      });
    },
  };

  /**
   * Object for handling lotto thai archive functionality.
   *
   * @typedef {Object} lottoThaiArchive
   * @property {number} articlesPerPage - The number of articles to display per page.
   * @property {number} currentPage - The current page number.
   * @property {NodeList} articleElements - The article elements in the archive.
   * @property {Element} endMessageElement - The end message element.
   * @property {Element} containerElement - The container element for the archive.
   * @property {(function():void)} init - Initializes the archive functionality.
   * @property {(function():void)} showNextPage - Shows the next page of articles.
   * @property {(function():void)} addScrollListener - Adds a scroll listener to the window.
   */
  const lottoThaiArchive = {
    /**
     * The number of articles to display per page.
     * @type {number}
     */
    articlesPerPage: Number(wpcbLotto.perPage),
    /**
     * The current page number.
     * @type {number}
     */
    currentPage: 1,
    /**
     * The article elements in the archive.
     * @type {NodeList}
     */
    articleElements: document.querySelectorAll(".wpcb-lotto-thai-archive"),
    /**
     * The end message element.
     * @type {Element}
     */
    endMessageElement: document.getElementById("wpcbLottoThaiEndMessage"),
    /**
     * The container element for the archive.
     * @type {Element}
     */
    containerElement: document.getElementById("wpcbLottoThaiArchiveContainer"),

    /**
     * Initializes the archive functionality.
     *
     * @returns {void}
     */
    init() {
      if (this.containerElement === null) return;

      this.showNextPage();
      this.addScrollListener();
    },

    /**
     * Shows the next page of articles.
     *
     * @returns {void}
     */
    showNextPage() {
      const start = (this.currentPage - 1) * this.articlesPerPage;
      const end = start + this.articlesPerPage;

      for (let i = start; i < end && i < this.articleElements.length; i++) {
        this.articleElements[i].style.display = "block";
      }

      if (end < this.articleElements.length || this.currentPage === 1) {
        const pageElement = document.createElement("div");
        pageElement.className = "wpcb-lotto-thai-archive-page";

        const pageLineElement = document.createElement("div");
        pageLineElement.className = "wpcb-lotto-thai-archive-page__line";

        const pageSpanElement = document.createElement("span");
        pageSpanElement.className = "wpcb-lotto-thai-archive-page__title";
        pageSpanElement.textContent = `Page ${this.currentPage + 1}`;

        // Create elements for pagination.
        pageElement.appendChild(pageLineElement.cloneNode(true));
        pageElement.appendChild(pageSpanElement);
        pageElement.appendChild(pageLineElement.cloneNode(true));

        this.articleElements[end]?.insertAdjacentElement("beforebegin", pageElement);
      }

      this.currentPage++;
    },

    /**
     * Adds a scroll listener to the window.
     *
     * @returns {void}
     */
    addScrollListener() {
      window.addEventListener("scroll", () => {
        if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10) {
          if ((this.currentPage - 1) * this.articlesPerPage < this.articleElements.length) {
            this.showNextPage();
          } else {
            this.endMessageElement.style.display = "block";
          }
        }
      });
    },
  };

  // Wait for the DOM content to be fully loaded before running scripts
  document.addEventListener("DOMContentLoaded", () => {
    // Initialize tab switching functionality
    initializeTabs();

    // Set up lotto number checking functionality
    lottoThaiSingle.handleChecking();

    // Set up form submission handling for number checking
    lottoThaiSingle.handleCheckingSubmit();

    // Initialize the lotto Thai archive functionality
    lottoThaiArchive.init();
  });
})();
